import Siema from './siema.min';
import 'bootstrap/dist/js/bootstrap';
import $ from 'jquery';

function DOMContentLoaded() {
    var width = screen.width;
    // slider only for mobile
    if(width <= 770) {
        function handleSlideChange () {
            const items = $('.slider-carousel-indicators li');
            items.removeClass('active');
            items.eq(this.currentSlide).addClass('active');
        }

        new Siema({
            selector: '.mobile-slider',
            duration: 200,
            easing: 'ease-out',
            perPage: 1,
            startIndex: 0,
            draggable: true,
            multipleDrag: true,
            threshold: 20,
            loop: false,
            rtl: false,
            onInit: () => {},
            onChange: handleSlideChange,
        });
    }

    $(".scroll-to-contact-us").click(function() {
        $([document.documentElement, document.body]).animate({
            scrollTop: $(".section-8").offset().top
        }, 1000);
    });

    $('.dropdown-toggle').dropdown();
}

document.addEventListener("DOMContentLoaded", DOMContentLoaded);
